<template>
<v-dialog v-model="dialog" width="750">
  <v-card>
    <v-container class="pa-3" fluid>
      <div class="d-flex">
        <div class="text-subtitle-1 font-weight-bold">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </div>
      <div class="mt-3">
        <v-divider></v-divider>
      </div>
      <div class="mt-3 ml-3">
        <v-btn color="grey" outlined>
          <div>okre</div>
          <v-spacer></v-spacer>
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </div>
      <div style="height:350px;overflow:auto;">
        <v-data-table :headers="headers" :items="objects">
          <template v-slot:[`item.ob_owner`]="{ item }">
            {{ item.ob_owner | username }}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ item.updated_at | updateTime }}
          </template>
        </v-data-table>
      </div>
      <div class="justify-end d-flex pa-3">
        <v-btn small @click="dialog=false" color="primary">我知道了</v-btn>
      </div>
    </v-container>
  </v-card>
</v-dialog>
</template>

<script>
import * as moment from 'moment';
export default {
  name: 'StObjectParentsDlg',
  props: ['title', 'objects'],
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: '目标',
          align: 'start',
          value: 'ob_name'
        },
        {
          text: '负责人',
          align: 'start',
          value: 'ob_owner'
        },
        {
          text: '部门',
          align: 'start',
          value: 'ob_department_name'
        },
        {
          text: '更新时间',
          align: 'start',
          value: 'date'
        }
      ]
    }
  },
  methods: {
    open() {
      this.dialog = true;
    }
  },
  filters: {
    updateTime: function (date) {
      let day = new Date(date);
      day = moment(day).format('MM/DD HH:mm');
      return day
    }
  }
}
</script>
